import axios from 'axios';

export const baseApiUrl = "https://8qy0m7fwg4.execute-api.ap-south-1.amazonaws.com/dev"

export const api = axios.create(
    {
        baseURL: baseApiUrl,
        headers: {
            "x-api-key": "fnn3TYLJlU1N1VpQv064FfJK5KWhe455tBNXyLyh",
        }
    }
);
