import React from "react"
import { Col, Form, Row } from "react-bootstrap"
import { Section } from "./Section"
import { Controller, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import SelectBox from "../../Reuseable/RUSelectBox"
import { client } from "../../../App"
import { usePatch } from "../../../Hooks/usePatch"
import { getContrastingColor, normalizeNumber } from "../../../Utilities/commonFn"
import { favColorOptions } from "./AllSelectOptions"

const formatOptionLabel = ({ label, value }) => {
  return (
    <div style={{ display: "flex" }}>
      <div style={{ background: value, color: getContrastingColor(value) }}>{label}</div>
    </div>
  )
}

const schema = yup.object().shape({
  notes: yup.string().nullable(),
})

const NotesForm = ({ contact, type, handleEditForm, handleCloseForm, isApprover }) => {
  const [edit, setEdit] = React.useState(type === "edit" ? true : false)
  const formName = "notes"

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    reset,
    control,
    watch,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      notes: contact?.Notes ?? null,
    },
  })

  React.useEffect(() => {
    reset({
      notes: contact?.Notes ?? null,
    })
  }, [contact, reset])

  const isPhysicallyChallenged = watch("physicallyChallenged")

  const handleEditShow = () => {
    reset()
    handleEditForm(formName)
    setEdit(true)
  }

  const handleEditClose = () => {
    handleCloseForm(formName)
    reset()
  }

  const onSuccess = async (data) => {
    if (!data.status) return
    await client.setQueryData(["contacts"], (oldData) => {
      return {
        status: data.status,
        data: oldData?.data?.map((contact) => {
          if (contact?.SK === data?.data?.SK) {
            return { ...contact, ...data?.data }
          }
          return contact
        }),
      }
    })
    handleCloseForm(formName)
  }

  const { mutate, isLoading } = usePatch(
    "update-contact",
    `/2-resource/${contact?.SK}`,
    onSuccess,
    () => {}
  )

  const onSubmit = (e) => {
    let data = {
      Notes: e?.notes,
    }
    mutate(data)
  }
  const update = edit && type === "edit" ? true : false
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Section
        title='Notes'
        onShowEdit={handleEditShow}
        onCloseEdit={handleEditClose}
        isLoading={isLoading}
        type={type}
        disableUpdate={isDirty}
        edit={edit}
        isApproved={contact?.Approval_Status === "Approved"}
        isApprover={isApprover}
      >
        <Row>
          <Col md={12}>
            {update ? (
              <Form.Group controlId='notes'>
                <Form.Label>Notes</Form.Label>
                <Form.Control
                  {...register("notes")}
                  as='textarea'
                  type='text'
                  placeholder='Enter notes'
                />
                {errors.notes && (
                  <Form.Control.Feedback type='invalid'>
                    {errors.notes.message}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            ) : (
              <p>Notes : {contact?.Notes ? contact?.Notes : "None"}</p>
            )}
          </Col>
        </Row>
      </Section>
    </Form>
  )
}

export default NotesForm
