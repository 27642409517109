import React, { useEffect, useRef, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import {
  ClickAwayListener,
  Collapse,
  FormControl,
  FormHelperText,
  IconButton,
  TextField,
} from "@mui/material";
import { useMutate } from "../../../Hooks/useMutate";
import { client } from "../../../App";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  setActiveContact,
  setAlertMessage,
} from "../../../redux/Data/actionCreator";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDelete } from "../../../Hooks/useDelete";
import DeleteIcon from "@mui/icons-material/Delete";
import DoneIcon from "@mui/icons-material/Done";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Button, Card, Col, Spinner } from "react-bootstrap";
import UIModal from "../../Reuseable/RuModal";
import { api } from "../../../Services";
import { getUserRole, phoneRegExp } from "../../../Utilities/commonFn";
import { ContactCard } from "./style";
import jwtDecode from "jwt-decode";

const initialContactData = {
  Name: null,
  EmailId: null,
  PhoneNo: null,
};

const schema = yup.object().shape({
  Name: yup.string().required("Name is Required"),
  EmailId: yup.string().email("EmailId is not valid").nullable(),
  PhoneNo: yup.string().nullable(),
});

const LeftColumn = ({ contacts, isContactsFetching, leftColRef }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.Auth.user);
  const userDetails = useSelector((state) => state.Auth);
  const userRole = localStorage.getItem("token")
    ? jwtDecode(localStorage.getItem("token"))?.userRole
    : "";
  const { access, isCreator } = getUserRole(userRole);
  const [addContact, setAddContact] = useState(false);
  const activeContact = useSelector((state) => state.Data.activeContact);
  const contactNameRef = useRef(null);
  const [loadingMore, setLoadingMore] = useState(false);
  const [searchContact, setSearchContact] = useState("");
  const [filteredContacts, setFilteredContacts] = useState([]);

  useEffect(() => {
    if (searchContact.length > 0) {
      setFilteredContacts(
        contacts?.data?.filter(
          (contact) =>
            contact?.Name?.toLowerCase().includes(
              searchContact?.toLowerCase()
            ) ||
            contact?.EmailId?.toLowerCase().includes(
              searchContact?.toLowerCase()
            ) ||
            contact?.PhoneNo?.toLowerCase().includes(
              searchContact?.toLowerCase()
            )
        )
      );
    } else {
      setFilteredContacts(contacts?.data);
    }
  }, [searchContact, contacts?.data]);

  useEffect(() => {
    if (addContact) contactNameRef?.current?.focus();
  }, [addContact]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    getValues,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: initialContactData,
  });

  useEffect(() => {
    const subscription = watch((value) => dispatch(setActiveContact(value)));
    return () => subscription.unsubscribe();
  }, [watch, dispatch]);

  const handleFocusLeftCol = () => {
    leftColRef.current?.focus();
  };

  const handleKeyDown = (event) => {
    if (event.key === "ArrowDown") {
      event.preventDefault();
      let activeIndex = contacts?.data?.findIndex(
        (contact) => contact?.SK === activeContact?.SK
      );
      if (activeIndex === contacts?.data?.length - 1) return;
      dispatch(setActiveContact(contacts?.data?.[activeIndex + 1]));
    }

    if (event.key === "ArrowUp") {
      event.preventDefault();
      let activeIndex = contacts?.data?.findIndex(
        (contact) => contact?.SK === activeContact?.SK
      );
      if (activeIndex === 0) return;
      dispatch(setActiveContact(contacts?.data?.[activeIndex - 1]));
    }
  };

  useEffect(() => {
    const leftColElement = leftColRef.current;
    leftColElement.addEventListener("keydown", handleKeyDown);
    // leftColElement.addEventListener('focus', handleFocus);

    return () => {
      leftColElement.removeEventListener("keydown", handleKeyDown);
      // leftColElement.removeEventListener('focus', handleFocus);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeContact, contacts, dispatch]);

  useEffect(() => {
    if (
      contacts?.data &&
      activeContact &&
      Object.keys(activeContact).length !== 0
    ) {
      dispatch(setActiveContact(contacts?.data?.[0]));
    }
  }, [filteredContacts]);

  const handleSearchContact = (e) => {
    setSearchContact(e.target.value);
  };

  const onSuccess = (data) => {
    if (!data.status) return;
    reset(initialContactData);
    dispatch(setActiveContact(data?.data));
    setAddContact(false);
    client.setQueryData(["contacts"], {
      status: data.status,
      data: [data?.data, ...contacts?.data],
    });
    dispatch(setAlertMessage({ message: data?.message, type: "success" }));
  };

  const handleAddNewContact = () => {
    setAddContact(!addContact);
    handleFocusLeftCol();
    dispatch(setActiveContact(initialContactData));
    leftColRef.current?.scrollTo(0, 0);
  };

  const handleClose = () => {
    reset(initialContactData);
    dispatch(setActiveContact(contacts?.data?.[0]));
    setAddContact(false);
  };

  const handleClickAway = () => {
    addContact && handleClose();
  };

  const { mutate } = useMutate("addContact", "2-resource", onSuccess, () => {});

  const onSubmit = (e) => {
    if (!getValues().PhoneNo && !getValues().EmailId) {
      dispatch(
        setAlertMessage({
          type: "error",
          message: "Phone or Email is required",
        })
      );
      return;
    }

    let data = {
      Name: e.Name,
      EmailId: e.EmailId,
      PhoneNo: e.PhoneNo,
      CreatedBy: localStorage.getItem("token")
        ? jwtDecode(localStorage.getItem("token"))?.userId
        : user,
      CreatedDate: moment().format("YYYY-MM-DD"),
    };
    handleFocusLeftCol();
    mutate(data);
  };

  const loadMoreReportData = async () => {
    setLoadingMore(true);
    try {
      const response = await api.get(`/2-resource/${contacts?.key?.SK}`, {
        headers: {
          AuthorizationToken: localStorage.getItem("token"),
        },
      });
      setLoadingMore(false);
      client.setQueryData([`contacts`], (oldData) => {
        return {
          status: oldData.status,
          data: [...oldData.data, ...response.data.data],
          key: response?.data?.key,
        };
      });
    } catch (error) {
      setLoadingMore(false);
      console.log("[LoadMore] error -->", error);
    }
  };

  const loadMore = contacts?.data?.length > 0 && contacts?.key?.SK && (
    <div className="table_header text-center mt-2">
      <Button onClick={loadMoreReportData}>
        {loadingMore ? (
          <>
            <Spinner animation="border" variant="light" size="sm" /> Loading
            More...
          </>
        ) : (
          "Load More"
        )}
      </Button>
    </div>
  );

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Col ref={leftColRef} className="left_column" md={3} tabIndex={0}>
        {console.log("access.add -->", access.add)}
        {access.add && (
          <div className="add_new" onClick={handleAddNewContact}>
            {addContact ? <KeyboardArrowUpIcon /> : <AddIcon />} Contact
          </div>
        )}
        {!isCreator && (
          <div className="tool_bar">
            {contacts?.data.length > 0 && (
              <IconButton onClick={() => client.invalidateQueries("contacts")}>
                <RefreshIcon
                  className={`refresh_icon ${
                    isContactsFetching ? "loading" : ""
                  } `}
                />
              </IconButton>
            )}
          </div>
        )}
        <div className={`contacts`}>
          <Collapse in={addContact}>
            <Card className="add_new_contact_card">
              <Card.Body>
                <form
                  className="add_new_contact"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <FormControl fullWidth id="Name">
                    <TextField
                      inputRef={contactNameRef}
                      error={errors?.Name?.message}
                      {...register("Name")}
                      placeholder="Enter Name"
                      variant="outlined"
                    />
                    <FormHelperText error={errors?.Name?.message}>
                      {errors?.Name?.message}
                    </FormHelperText>
                  </FormControl>

                  <FormControl fullWidth id="EmailId">
                    <TextField
                      error={errors?.EmailId?.message}
                      {...register("EmailId")}
                      placeholder="Enter Email ID"
                      variant="outlined"
                    />
                    <FormHelperText error={errors?.EmailId?.message}>
                      {errors?.EmailId?.message}
                    </FormHelperText>
                  </FormControl>

                  <FormControl fullWidth id="PhoneNo">
                    <TextField
                      error={errors?.PhoneNo?.message}
                      {...register("PhoneNo")}
                      placeholder="Enter Phone No."
                      variant="outlined"
                    />
                    <FormHelperText error={errors?.PhoneNo?.message}>
                      {errors?.PhoneNo?.message}
                    </FormHelperText>
                  </FormControl>

                  <IconButton className="done_icon" type="submit">
                    <DoneIcon />
                  </IconButton>
                </form>
              </Card.Body>
            </Card>
          </Collapse>

          <div className="search_bar">
            {filteredContacts?.length > 0 && (
              <FormControl fullWidth id="Name">
                <TextField
                  placeholder="Search"
                  value={searchContact}
                  onChange={handleSearchContact}
                  variant="outlined"
                />
              </FormControl>
            )}
          </div>

          {filteredContacts?.map((contact, index) => (
            <Contact
              handleFocusLeftCol={handleFocusLeftCol}
              activeContact={activeContact}
              key={index}
              contact={contact}
              contacts={contacts}
            />
          ))}
          {filteredContacts?.length === 0 && (
            <div className="d-flex justify-content-center">
              <p>No data</p>
            </div>
          )}

          {loadMore}
        </div>
      </Col>
    </ClickAwayListener>
  );
};

export default LeftColumn;

const Contact = ({ contact, activeContact, handleFocusLeftCol }) => {
  const dispatch = useDispatch();
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] =
    React.useState(false);
  const contactRef = useRef(null);
  const user = useSelector((state) => state.Auth.user);
  const { access } = getUserRole(user);

  useEffect(() => {
    if (activeContact?.SK === contact?.SK) {
      contactRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start",
      });
    }
  }, [activeContact, contact]);

  const handleActiveContact = () => {
    // console.log(" dispatch(setActiveContact(contacts?.data?.[0]))",  dispatch(setActiveContact(contacts?.data?.[0])))
    dispatch(setActiveContact(contact));
    handleFocusLeftCol();
  };

  const onSuccessDelete = (data) => {
    setShowDeleteConfirmationModal(false);
    // dispatch(setAlertMessage({ message: "Contact Deleted Successfully", type: "success" }))
    client.setQueryData(["contacts"], (oldData) => {
      return {
        status: oldData.status,
        data: oldData.data.filter(
          (contact) => contact?.SK !== activeContact?.SK
        ),
      };
    });
    dispatch(setAlertMessage({ message: data?.message, type: "success" }));
  };

  const { mutate, isLoading } = useDelete(
    "delete-contact",
    `/2-resource`,
    onSuccessDelete,
    () => {}
  );

  const handleDelete = () => {
    setShowDeleteConfirmationModal(false);
    mutate(contact?.SK);
  };

  return (
    <ContactCard status={contact?.Approval_Status}>
      <Card className="contact_card" ref={contactRef}>
        <Card.Body className="contact_card_body" onClick={handleActiveContact}>
          <div
            className={`contact ${
              activeContact?.SK === contact?.SK ? "active" : ""
            }`}
          >
            <div>
              <div className="fw-bold">{contact?.Name}</div>
              <div className="email_id">
                <span className="fw-semibold">{contact?.EmailId} </span>
              </div>
              <div className="phone_number">
                <span className="fw-semibold">{contact?.PhoneNo}</span>
              </div>
            </div>

            {access.delete && (
              <IconButton
                className="delete_icon"
                onClick={() => setShowDeleteConfirmationModal(true)}
              >
                <DeleteIcon />
              </IconButton>
            )}
          </div>
        </Card.Body>

        <ConfirmModal
          show={showDeleteConfirmationModal}
          onHide={() => setShowDeleteConfirmationModal(false)}
          onConfirm={handleDelete}
          isLoading={isLoading}
          contact={contact}
        />
      </Card>
    </ContactCard>
  );
};

const ConfirmModal = ({ show, onHide, isLoading, contact, onConfirm }) => {
  return (
    <UIModal show={show} onHide={onHide}>
      <div className="fs-5 mb-3 text-center">
        Are you sure you want to Delete this Contact{" "}
        <span className="fw-bold">{contact?.Name}</span>
      </div>

      <div className="d-flex justify-content-center gap-3">
        <Button variant="secondary" onClick={onHide}>
          Cancel
        </Button>

        <Button variant="primary" onClick={onConfirm} disabled={isLoading}>
          {isLoading ? "Loading..." : "Confirm"}
        </Button>
      </div>
    </UIModal>
  );
};
