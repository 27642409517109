import { masterDataSet } from "../../MasterData/layoutRules"
import { ActionTypes } from "./actions"

const checkLogin = localStorage.getItem("isLoggedIn") ?? false
const authToken = localStorage.getItem("token") ?? ""
const user = localStorage.getItem("user") ?? ""
const userRoleList = localStorage.getItem("userRoleList") ?? [];
const rolesArray = (userRoleList && typeof userRoleList === 'string') ? userRoleList.trim().split(',') : [];

const intialState = {
	isLoggedIn: checkLogin,
	authToken: authToken,
	user: user,
	showNote: false,
	sessionTimeout: false,
	layout: masterDataSet,
	userRoleList: rolesArray
}

export const AuthReducer = (state = intialState, { type, payload }) => {
	console.log("AuthReducer --> payload", payload?.userRoleList)
	switch (type) {
		case ActionTypes.LOGIN:
			return {
				...state,
				isLoggedIn: payload.isLoggedIn,
				authToken: payload.authToken,
				user: payload.user,
				userRoleList: payload.userRoleList
			}
		case ActionTypes.LOGOUT:
			return {
				...state,
				isLoggedIn: payload.isLoggedIn,
				authToken: payload.authToken,
				user: payload.user,
				userRoleList: payload.userRoleList
			}
		case ActionTypes.SHOW_NOTE:
			return {
				...state,
				showNote: payload,
			}
		case ActionTypes.SET_SESSION_TIMEOUT:
			return {
				...state,
				sessionTimeout: payload,
			}
		default:
			return state
	}
}
