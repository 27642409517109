import { Button, Card, Spinner } from "react-bootstrap"
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector } from "react-redux";
import { getUserRole } from "../../../Utilities/commonFn";

export const Section = ({ title, disableUpdate, children, type, onShowEdit, onCloseEdit, edit, isLoading, isApproved, isApprover = false}) => {

    const user = useSelector((state) => state.Auth.user);
    const { access } = getUserRole(user);

    return (
        <Card className="section">
            <Card.Title className="section_title">
                <div className='d-flex justify-content-between'>
                    <span className="fw-semibold">{title}</span>
                    <span className="d-flex gap-2">

                        {type !== "edit" ? access.edit && !isApprover && <Button onClick={onShowEdit} className='p-1'>
                            <EditIcon fontSize='small' /> Edit
                        </Button> : <></>}

                        {type === "edit" ? <Button onClick={onCloseEdit}> <CloseIcon fontSize='small' /> Close</Button> : <></>}

                        {edit && type === "edit" ? <Button disabled={!disableUpdate} type="submit" className='p-1 position-relative'>
                            {isLoading ? <><Spinner animation="grow" size="sm" /> Updating...</> : <><SaveIcon fontSize="small" />Update</>}
                        </Button> : <></>}
                    </span>
                </div>
            </Card.Title>
            <Card.Body className="p-1">{children}</Card.Body>
        </Card >
    )
}