import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Link, NavLink } from "react-router-dom";
import HeaderWrap, { StyledPopup, RoleListItem } from "./style";
import logoutIcon from "../../../assets/images/icons/logoutB.svg";
import { userLogout } from "../../../redux/Auth/actionCreator";
import BurgerMenuButton from "../RuAnimationButton";
import marketingTool from "../../../assets/images/icons/MarketingTool.png";
import { api } from "../../../Services";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { setActiveContact } from "../../../redux/Data/actionCreator";
import jwtDecode from "jwt-decode";
import AddUserForm from "../../Pages/AddUserForm";
import UIModal from "../RuModal";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import { IconButton, Popover } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { userLogin } from "../../../redux/Auth/actionCreator";
import Alert from "react-bootstrap/Alert";
import CircularProgress from "@mui/material/CircularProgress";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import AddBoxIcon from "@mui/icons-material/AddBox";
import DoneAllIcon from "@mui/icons-material/DoneAll";

const UIHeader = () => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [addUserModal, setAddUserModal] = React.useState(false);
  const [errorAlert, setErrorAlert] = React.useState(false);
  const [openPopUp, setOpenPopUp] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const anchorElRef = React.useRef(null);
  const userRole = localStorage.getItem("token")
    ? jwtDecode(localStorage.getItem("token"))?.userRole
    : "";
  const userId = localStorage.getItem("token")
    ? jwtDecode(localStorage.getItem("token"))?.userId
    : "";
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const layout = useSelector((state) => state.Auth.layout);

  const userRoleList = useSelector((state) => state.Auth.userRoleList);

  console.log("userRoleList -->", userRoleList);
  const { header } = layout;

  const navlink = [];

  const postLogout = async () => {
    try {
      const response = await api.post(
        "/broker-auth/logout",
        {},
        {
          headers: {
            authorizationToken: localStorage.getItem("token"),
          },
        }
      );
      console.log("[postLogout] response-->", response);
      response.data.logout && navigate("/login");
    } catch (error) {
      console.log("[postLogout] error-->", error.response);
    }
  };

  let navigate = useNavigate();
  const logoutUser = () => {
    postLogout();
    dispatch(userLogout());
  };
  const handleLogoClick = () => {
    dispatch(setActiveContact({}));
    navigate("/home/dashBoard");
    return;
  };

  const handleModalClose = () => {
    setAddUserModal(false);
  };

  const switchUserOpen = () => {
    setOpenPopUp(true);
  };

  const switchUserClose = () => {
    setOpenPopUp(false);
  };

  const roleList = ["SuperAdmin", "Admin", "Approver", "Creator"];

  const roleIconMap = {
    SuperAdmin: <SupervisorAccountIcon style={{ fontSize: "20px" }} />,
    Admin: <AdminPanelSettingsIcon style={{ fontSize: "20px" }} />,
    Approver: <DoneAllIcon style={{ fontSize: "20px" }} />,
    Creator: <AddBoxIcon style={{ fontSize: "20px" }} />,
  };

  const handleSwitchUser = async (val) => {
    console.log("called", val, userRoleList);
    if (userRoleList.includes(val)) {
      const obj = {
        userId: userId,
        role: val,
      };
      try {
        setOpenPopUp(false);
        setLoading(true);
        const response = await api.post("/1-resource/change", obj, {});
        if (response.status) {
          let result = {
            isLoggedIn: true,
            authToken: response?.data?.authorizationToken,
            user: response?.data?.authorizationToken
              ? jwtDecode(response.data.authorizationToken).userRole
              : "Tester",
            userRoleList: response?.data?.authorizationToken
              ? jwtDecode(response.data.authorizationToken).eligibleRoles
              : [],
          };
          dispatch(userLogin(result));
          navigate("/home/dashboard");
          setTimeout(() => {
            setLoading(false);
          }, 2000);
        }
      } catch (error) {
        setLoading(false);
        console.log("[handleSwitchUser] error -->", error);
      }
    } else {
      setOpenPopUp(false);
      setErrorAlert(true);
    }
  };

  return (
    <div>
      <HeaderWrap>
        <nav
          className={`navbar navbar-expand-md navbar-light bg-${header.background} shadow position-fixed w-100 z-index-navbar`}
        >
          <div className="container-fluid pe-0">
            <div className="navbar-item">
              {header.logo.show && (
                <div className="header-left">
                  <div ref={anchorElRef}>
                    <IconButton
                      aria-label="add an alarm"
                      onClick={switchUserOpen}
                    >
                      <ManageAccountsIcon style={{ fontSize: "40px" }} />
                    </IconButton>
                  </div>
                  <div onClick={handleLogoClick}>
                    <img
                      className="header_logo"
                      src={marketingTool}
                      alt="logo"
                    />
                  </div>
                </div>
              )}
              <div className="role">
                {userRole === "SuperAdmin" && (
                  <button onClick={() => setAddUserModal(true)}>
                    Invite User
                  </button>
                )}
                <div className="subrole">
                  <p>Role : {userRole}</p>
                  <p>Email : {userId}</p>
                </div>
              </div>
              <div className="menu-display">
                <div className="hide-menu">
                  <div>
                    <div
                      className="border-0 p-0 btn"
                      id="basic-button"
                      aria-controls={open ? "basic-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                      onClick={handleClick}
                    >
                      <BurgerMenuButton open={open} />
                    </div>
                    <Menu
                      id="basic-menu"
                      className="basic-menu"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                    >
                      <div className="position-relative">
                        {navlink?.map((nav) => {
                          return (
                            <MenuItem
                              key={nav?.navLink}
                              className="fw-bold blue-theme"
                              onClick={handleClose}
                            >
                              <NavLink
                                className="fw-bold blue-theme"
                                to={nav?.navLink}
                              >
                                <div className="menu-item">{nav?.item}</div>
                              </NavLink>
                            </MenuItem>
                          );
                        })}
                        {header.logout && (
                          <MenuItem
                            className="fw-bold blue-theme "
                            to="#"
                            type="button"
                            onClick={logoutUser}
                            title="Logout"
                          >
                            <div className="menu-item">Logout</div>
                          </MenuItem>
                        )}
                      </div>
                    </Menu>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="collapse navbar-collapse justify-content-start"
              id="navbarNavAltMarkup"
            >
              <ul className="navbar-nav">
                {header.logout && (
                  <li className="nav-item">
                    <Link
                      className="nav-link"
                      to="#"
                      type="button"
                      onClick={logoutUser}
                      title="Logout"
                    >
                      <div className="profileLetter d-flex justify-content-center align-items-center">
                        <img
                          src={logoutIcon}
                          height={29}
                          width={29}
                          alt="Logout"
                        ></img>
                      </div>
                    </Link>
                  </li>
                )}

                {navlink?.map((nav, index) => {
                  return (
                    index + 1 <= header.menutoshow && (
                      <li key={nav?.item + index} className="nav-item mx-2">
                        <NavLink
                          className={`navbar-brand nav-link ${
                            header.background === "black"
                              ? "text-white"
                              : "text-black"
                          }`}
                          to={nav?.navLink}
                          title={nav?.item}
                        >
                          {nav?.item}
                        </NavLink>
                      </li>
                    )
                  );
                })}
              </ul>
            </div>
          </div>
        </nav>
        <UIModal
          show={addUserModal}
          onHide={handleModalClose}
          size="lg"
          closeButton={true}
        >
          <AddUserForm handleCloseModal={handleModalClose} />
        </UIModal>

        <Popover
          open={openPopUp}
          anchorEl={anchorElRef.current}
          onClose={switchUserClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <StyledPopup>
            <h6>Switch To</h6>
            {roleList.map((role) => (
              <RoleListItem key={role} onClick={() => handleSwitchUser(role)} disabled={userRole === role}>
                {roleIconMap[role]} {role}
              </RoleListItem>
            ))}
          </StyledPopup>
        </Popover>
        <Snackbar
          open={errorAlert}
          autoHideDuration={6000}
          onClose={() => setErrorAlert(false)}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          sx={{
            marginTop: "2%",
            width: "100vw",
          }}
        >
          <Alert
            onClose={() => setErrorAlert(false)}
            variant="danger"
            style={{ width: "100%", textAlign: "center" }}
            dismissible
          >
            Sorry! you didn't have the access for this role
          </Alert>
        </Snackbar>
      </HeaderWrap>

      <UIModal
        show={loading}
        onHide={() => setLoading(false)}
        size="sm"
        closeButton={false}
      >
        {" "}
        <div style={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress disableShrink size={40} thickness={4} />
        </div>
      </UIModal>
    </div>
  );
};
export default UIHeader;
