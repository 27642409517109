import { toast } from "react-toastify";
import { ActionTypes } from "./actions";
import { message } from "antd";

const intialState = {
    activeContact: {},
    alertMessage: '',
};

export const DataReducer = (state = intialState, { type, payload }) => {
    switch (type) {
        case ActionTypes.SET_ACTIVE_CONTACT:
            return {
                ...state,
                activeContact: payload
            }
        case ActionTypes.SET_ALERT_MESSAGE:
            // toast(payload.message, {
            //     type: payload.type,
            //     position: "bottom-right"
            // })
            message[payload.type](payload.message); 
            return {
                ...state,
                alertMessage: payload
            }


        default:
            return state;
    }
}