import React, {useState} from "react";
import { Validators } from "../../../Utilities/validator";
import { Forms } from "../../Reuseable/RuForms";
import { api } from '../../../Services';
import { setAlertMessage } from "../../../redux/Data/actionCreator";
import { useDispatch } from 'react-redux';


function AddUserForm({handleCloseModal}) {

  const dispatch = useDispatch();
  const roleOption = [
    { value: "Admin", label: "Admin" },
    { value: "Creator", label: "Creator" },
    { value: "Approver", label: "Approver" },
  ];
  const [changeInForm, setChangeInForm] = useState(false);
  const [loading, setLoading] = useState(false);

  const onSubmit = async (form, valid) => {
    console.log("form--->", form)
    if (valid) {
      setLoading(true);
      form.password = form.password.trim();
      const obj = {
        userName: form.username,
        password: form.password,
        userId: form.username,
        userRole: form.role,
        name: form.name,
        phone:form.phone,
        email: form.email
      }
      try {
        const response = await api.post('/1-resource/create', obj, {
        })
        console.log("[onSubmit] response-->", response);
        if (response.status) {
          handleCloseModal();
          setLoading(false);
          dispatch(setAlertMessage({
            message: "User Added Successfully",
            type: 'success',
        }))
        }
      } catch (error) {
        console.log("[onSubmit] error --->", error)
        setLoading(false);
      }
    }


  }
  return (
   <div >
    
    <Forms title={""} formArr={[
         {
            label: "Name",
            name: "name",
            type: "text",
            placeholder: "Name",
            validators: [
              { check: Validators.required, message: "This Field is required" },
              { check: Validators.alphabetic, message: "Please enter correct name" }
            ],
            error: false,
            errormsg: "",
            value: "",
            show: true,
            colSpan: 6
          },
          {
            label: "UserName",
            name: "username",
            type: "text",
            placeholder: "User Name",
            validators: [
              { check: Validators.required, message: "This Field is required" },
            ],
            error: false,
            errormsg: "",
            value: "",
            show: true,
            colSpan: 6
          },
          {
            label: "Password",
            name: "password",
            type: "password",
            placeholder: "Password",
            validators: [
              { check: Validators.required, message: "This Field is required" },
              { check: Validators.password, message: "Password is not Valid" }
            ],
            error: false,
            errormsg: "",
            value: "",
            show: true,
            colSpan: 6
          },
        {
          label: "Email (optional)",
          name: "email",
          type: "email",
          placeholder: "Email ID",
          validators: [
            { check: Validators.email, message: "Email is not Valid" }
          ],
          error: false,
          errormsg: "",
          value: "",
          show: true,
          colSpan: 6
        },
        {
            label: "Phone No (optional)",
            name: "phone",
            type: "text",
            placeholder: "Phone Number",
            error: false,
            errormsg: "",
            value: "",
            show: true,
            colSpan: 6,
            validators: [
              { check: Validators.number, message: "Please enter valid Number" }
            ],
          },
        {
            label: "Role",
            name: "role",
            type: "select",
            placeholder: "Select Role",
            validators: [
              { check: Validators.required, message: "This Field is required" },
            ],
            options: roleOption,
            error: false,
            errormsg: "",
            value:"",
            show: true,
            colSpan: 6
          },
      
      ]}
        submitBtn={"Create"}
        resetBtn = {true}
        onSubmit={onSubmit} loading={loading} setChangeInForm={setChangeInForm} changeInForm={changeInForm}
      />
   </div>
  );
}

export default AddUserForm;
